$block-green: #279a16;
$block-blue: #2b6081;
$block-black: #2e2e2e;

.large-image-stats {
  margin-top: 60px;

  &__top-image {
    margin-bottom: 14px;

    img {
      height: auto;
      width: 100%;
    }
  }

  &__stats-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media (max-width: 850px) {
      flex-wrap: wrap;
    }
  }

  &__stats-each {
    align-items: center;
    color: $text-light;
    display: flex;
    height: 280px;
    margin-bottom: 10px;
    min-width: 200px;
    padding: 10px 40px;
    text-align: center;
    width: 32.6%;

    @media (max-width: 850px) {
      flex: 1;
      height: 250px;
      justify-content: center;
      padding: 10px 20px;
    }


    &:nth-of-type(1) {
      background: $block-green;
    }

    &:nth-of-type(2) {
      background: $block-blue;
    }

    &:nth-of-type(3) {
      @include block-cut(40px, $block-black);
      background: $block-black;
    }

    h3 {
      @include responsive-font(20px, 24px);
      margin: 0 0 20px;
    }

    p {
      @include responsive-font(12px, 14px);
    }
  }
}
