.site-footer {
  background: $primary;
  margin-top: 40px;
  padding: 20px 0;

  a {
    transition: all $fast;

    &:hover {
      opacity: .8;
    }
  }

  &__main {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 800px) {
      justify-content: center;
    }

    > div {
      min-width: 200px;

      @media (max-width: 644px) {
        min-width: 350px;
      }
    }


    h3 {
      @include responsive-font(18px, 20px);
      color: $text-light;
      margin-bottom: 30px;

      @media(max-width: 768px) {
        margin-bottom: 10px;
      }
    }

    ul {
      padding: 0;

      li {
        list-style: none;

        a {
          @include responsive-font(14px, 16px);
          color: $text-light;
          font-weight: $light !important;
        }
      }
    }
  }

  &__social {
    margin-bottom: 20px;

    a {
      align-items: center;
      background: $text-light;
      border-radius: 50%;
      display: inline-flex;
      height: 30px;
      justify-content: center;
      margin-right: 6px;
      width: 30px;

      &:hover {
        text-decoration: none;
      }

      i {
        color: $primary;
      }
    }
  }

  &__phone-block {
    a {
      color: $text-light;
      text-decoration: none;
    }
  }

  &__copyright {
    color: $text-light;
    font-size: 15px;
    font-weight: lighter;
    margin-top: 40px;
    text-align: center;
  }
}
