.-search {
  border-top: 1px solid $border-light;

  .element-heading {

    a {
      @include responsive-font(28px, 32px);
      color: $primary;
      display: block;
      padding: 20px;
      background: $border-light;
    }
  }
}
