$block-blue: #2b6081;

.section-image-stat {

  &:not(:first-of-type) {
    margin-top: 40px;
  }

  &__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    margin-bottom: 20px;
  }

  &__content-wrap {
    display: flex;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    > div:first-child {
      margin-right: 40px;
      width: 70%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    > div:last-child {
      width: 30%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .single-stat {
    @include block-cut(40px, $block-blue);
    align-items: center;
    background: $block-blue;
    color: $text-light;
    display: flex;
    height: 190px;
    justify-content: center;
    margin-bottom: 10px;
    min-width: 200px;
    padding: 10px 40px;
    text-align: center;
    width: 100%;
  }
}
