/**************************
  VARS
**************************/
$primary: $primary;
$read-more-bg: $primary;
$index-hover-image: $primary;

/**************************
  WP BASE BLOG STYLES
**************************/

@import 'wp-styles.scss';

.hive-page-header {
    a {
        color: #fff;
    }
}

/**************************
  BLOG INDEX STYLES
**************************/
article.type-post:not(:last-of-type) {
    margin-bottom: 40px;
}

.blog-index {

    &__image {
        content: '';
        height: 350px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: all $fast;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            transition: all ease 450ms;
        }
        &:hover:before {
            background: rgba($index-hover-image, .5);
        }

        @media(max-width: 768px) {
            height: 300px;
        }

        @media(max-width: 600px) {
            height: 250px;
        }
    }

    &__meta {
        a {
            text-decoration: none;
        }
    }

    &__title {
        @include responsive-font(24px, 28px);
        line-height: 30px;
        color: #000;
        transition: all ease 350ms;

        &:hover {
            color: $primary;
        }
    }

    &__excerpt {
        @include responsive-font(14px, 16px);
        line-height: 1.5;
    }

    &__extra {
        @include responsive-font(13px, 15px);
        padding: 5px 0;
        border-top: 1px solid #e7e7e7;
        border-bottom: 1px solid #e7e7e7;
        margin-top: 20px;

        a {
            color: #000;
            transition: all ease 350ms;
            &:hover {
                color: $secondary;
            }
        }
    }

    &__read-more {
        float: right;
        
        a {
            background: $primary;
            display: inline-block;
            padding: 4px 20px;
            font-weight: bold;
            color: $text-light;
            text-transform: uppercase;
            border: 2px solid $primary;
            position: relative;
            top: -6px;

            &:hover {
                color: $primary;
                background: $text-light;
            }
        }
    }
}


/**************************
  BLOG SINGLE STYLES
**************************/

.blog-single {

    &__header {
        
    }

    &__content {
        padding: 10px 0;
        h1 {
            color: #000;
            font-size: 28px;
        }
        p,
        ul,
        ol {
            @include responsive-font(18px, 17px);
            margin-bottom: 20px;
        }
        ol li {
            margin: 20px 0;
        }
    }

    &__title {
        @include responsive-font(26px, 34px);
        color: $primary;
        margin-bottom: 10px;
        margin-top: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid $primary;
    }

    &__image {
        content: '';
        height: 350px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        @media(max-width: 768px) {
            height: 300px;
        }

        @media(max-width: 600px) {
            height: 250px;
        }
    }
}


/**************************
  BLOG SIDEBAR STYLES
**************************/

.blog-sidebar {
    padding-left: 40px;

    aside section {
        background: #fff;
        padding-top: 0px;
        padding-bottom: 30px;
        margin-top: 0 !important;

        h2 {
            margin: 0;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            color: #000;
            padding: 15px 0;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                border-bottom: 1px solid #e7e7e7;
                margin: 10px 0;

                a {
                    color: #000;
                    font-size: 15px;
                    line-height: 25px;
                    text-decoration: none;
                    transition: all 250ms;


                    &:hover {
                        color: $secondary;
                    }
                }
            }
        }
    }
}


/**************************
  BLOG PAGINATION STYLES
**************************/

.blog-pagination {

    a {
        color: $text-dark;
    }
}


