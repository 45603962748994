.-contact {


  &__top-text {
    @include responsive-font(18px, 20px);
    margin-bottom: 40px;
    text-align: center;
  }

  &__content-wrap {
    display: flex;
    justify-content: space-between;

    @media (max-width: 700px) {
      flex-direction: column-reverse;
    }
  }

  &__form {
    width: 48%;

    @media (max-width: 700px) {
      width: 100%;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
    }
  }

  &__locations {
    width: 48%;

    @media (max-width: 700px) {
      width: 100%;
      margin-bottom: 60px;
    }

    iframe {
      height: 397px;
      width: 100%;
    }
  }

  &__locations--meta {

    .classification {
      @include responsive-font(18px, 20px);
      font-weight: $bold !important;
    }

    .phone-number {

      a {
        color: inherit;
        font-weight: $bold;
      }
    }

    .location-name {
      color: $primary;
      font-weight: $bold;
      margin-bottom: 0;
    }

    address {
      max-width: 330px;
    }

  }

  &__locations--map-wrap {
    @include block-cut(60px, $text-light);
    overflow: hidden;

    &::before {
      border-left-color: $text-light;
      right: -10px;
      transform: rotate(45deg) translate(35px, 0px);
    }
  }
}
