// All configuration is done through this constructs folder.
// Go through each of the files and customize the variables and mixins according
// to the design.

body {
  @include responsive-font;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
}

.container {
  position: relative;
}
