// ==[ Typography ]== //

// Font weights
$light: 300;
$regular: 400;
$bold: 700;

// Responsive font created below

$base-font-size: 18px; // Base Font
$mobile-font-size: 16px; // should be smaller

$base-font-family: 'Raleway', sans-serif;
$base-font-weight: $light;
$base-line-height: 28px;
$mobile-line-height: 24px;

/* ----Responsive Font Sizing------------------------------------------------ */

@mixin responsive-font($min-font-size: $mobile-font-size, $max-font-size: $base-font-size, $min-vw: 480px, $max-vw: 1200px) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    font-size: $min-font-size;

    @if $min-vw != $max-vw {
      @media only screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
    }

    @media only screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }

    } @else {
    @error"Detected mixed units. Please use the same units for all parameters.";
  }
}

@function strip-unit($number) { @return $number / ($number * 0 + 1); }


h1 {@include responsive-font(30px, 34px)}
h2 {@include responsive-font(26px, 30px)}
h3 {@include responsive-font(22px, 26px)}
h4,
h5,
h6 {@include responsive-font(18px, 22px)}
