.search-form {
  position: relative;
  width: 203px;

  input {
    border: 0;
    margin-bottom: 20px;
    padding-right: 40px !important;
    width: 203px;
  }

  i {
    color: $primary;
    position: absolute;
    right: 14px;
    top: 14px;
  }
}


input[type="text"],
input[type="email"],
textarea {
  -webkit-appearance: none;
  border: 1px solid $border-dark;
  border-radius: 0;
  font-weight: $regular;
  padding: 8px 12px;
  transition: all $fast;
  width: 100%;

  &:focus,
  &:active {
    @include shadow(1);
    border: 1px solid $primary;
    outline: none;
    transform: scale(1.04);
  }
}

.wpcf7 {
  label {
    font-weight: $bold;
  }
}
