.general-with-sidebar {
  border-right: 1px solid $primary;
  padding-right: 40px;

  @media (max-width: 767px) {
    border-right: 0;
    padding-right: 0;
  }

  .section {

    &:not(:last-of-type) {
      margin-bottom: 60px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__image {
      @include block-cut(110px, $text-light);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 300px;
      margin-bottom: 20px;
      overflow: hidden;

      &::before {
        border-left-color: $text-light;
        right: -10px;
        transform: rotate(45deg) translate(80px, 10px);
      }

      @media (max-width: 768px) {
        @include block-cut(100px, $text-light);
        height: 250px;
      }
    }

    &__content {
      @include responsive-font(16px, 18px);
    }

    button {
      margin-top: 10px;
    }
  }
}
