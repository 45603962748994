@mixin block-cut($size, $color) {
  position: relative;

  &::before {
    border-bottom: $size solid $text-light;
    border-left: $size solid $color;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    transition: all $fast;
    width: 0;
    z-index: 40;
  }
}
