/*

  HIVE STARTER KIT Rundown
  Included is one template for the 404 page. It was created using SCSSs %placeholder
  element. This means the CSS is not compiled unless the %placeholder
  element is called.

  The template was created in ../templates/template-404
  It is imported into this file, and can be used by extending the placeholder
  element onto .not-found

  Currently there is one template:
  %404-1

*/

@import '../templates/template-404';

.not-found {
  @extend %404-1;
}
