.home__section-1,
.home__section-2,
.home__section-3 {
  margin-bottom: 60px;
}

.home-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    position: relative;
    text-decoration: none;
  }

  &__each {
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    width: 49%;

    @media (max-width: 762px) {
      width: 100%;
    }

    &:last-of-type .home-blocks__each-meta {
      @include block-cut(24px, $secondary);
    }

    &:hover .home-blocks__each-meta {
      background: $primary;

      &::before {
        border-left-color: $primary;
      }
    }

    &:hover .home-blocks__each-image {
      opacity: .8;
    }
  }

  &__each-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 250px;
  }

  &__each-meta {
    background: $secondary;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 20px;
    transition: all $fast;

    h3 {
      @include responsive-font(17px, 19px);
      color: $text-light;
      margin: 0;
      text-transform: uppercase;
    }

    i {
      color: $text-light;
      margin-left: 8px;
      position: relative;
      top: 2px;
    }

    p {
      @include responsive-font(14px, 15px);
      color: $text-light;
      line-height: 24px;
      margin-bottom: 0;
      margin-top: 10px;

      @media (max-width: 768px) {
        line-height: 24px;
      }
    }
  }
}

.home__about {

  &-background {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    margin-bottom: -40px;
    padding: 40px 0;
  }

  &-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-content {
    color: $text-light;
    width: 55%;

    @media (max-width: 768px) {
      width: 100%;
    }

    h2 {
      @include responsive-font(34px, 42px);
      text-transform: uppercase;
    }

    p {
      @include responsive-font(15px, 16px);
    }
  }

  &-image {
    text-align: right;
    transition: all $fast;
    width: 44%;

    @media (max-width: 768px) {
      margin-top: 20px;
      text-align: center;
      width: 100%;
    }

    img {
      @include shadow(4);
      max-width: 100%;
    }
  }
}
