.element-heading {
  color: $primary;
  font-weight: 700;
  line-height: 40px;
  margin-top: 0;

  @media (max-width: 762px) {
    line-height: 30px;
  }
}

.sub-text {
  @include responsive-font(16px, 18px);
  font-weight: $light;
}
