.notice-bar {
    background-color: #327e3d;
    color: #fff;
    padding: 10px;
    border-bottom: 14px solid #303434;
    h1, h2, h3, h4, h5 {
        margin: 0 0 10px;
    }
    p {
        margin: 0;
        line-height: 1.3;
    }
    &__close {
        background-color: #000;
        color: #fff;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        border: 0;
        line-height: 1;
        &:hover {
            background: #fff;
            color: #000;
        }
    }
}