// ../components/template-image-stat;

.manufacturer {
  margin-top: 40px;

  &__wrap {
    margin-top: 40px;

    &:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }

  &__logo {
    max-height: 100px;
    width: auto;
  }

  &__content-wrap {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    @media (max-width: 650px) {
      flex-direction: column;
    }
  }

  &__product-image {
    @include block-cut(120px, $text-light);
    overflow: hidden;
    width: 35%;

    @media (max-width: 650px) {
      width: 100%;
    }

    &::before {
      border-left-color: $text-light;
      right: -10px;
      transform: rotate(45deg) translate(100px, 10px);
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  &__description {
    background: $border-light;
    padding: 20px;
    width: 62%;

    @media (max-width: 650px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }
}
