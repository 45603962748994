.product-page {
  border-right: 1px solid $primary;
  padding-right: 40px;

  @media (max-width: 767px) {
    border-right: 0;
    padding-right: 0;
  }
}

.product-info {

  &__image {
    max-height: 100px;
    width: auto;
  }

  .element-heading {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  &__gallery {
    margin: 10px 0;

    img {
      height: auto;
      max-width: 32%;
    }
  }

  &__content {
    @include responsive-font(16px, 18px);
  }
}

.product-list {
  margin-bottom: 60px;
  margin-top: 60px;

  .element-heading {
    margin-bottom: 40px;
  }

  &__each {
    display: flex;
    justify-content: space-between;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    &:not(:last-of-type) {
      margin-bottom: 60px;
    }
  }

  &__image {
    align-self: center;
    width: 30%;

    @media (max-width: 500px) {
      width: 100%;
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  &__meta {
    width: 65%;

    @media (max-width: 500px) {
      width: 100%;
    }

    h3 {
      @include responsive-font(22px, 20px);
      font-weight: $bold !important;
    }

    p {
      @include responsive-font(17px, 16px);
    }
  }
}
