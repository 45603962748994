.post-featured-image {
  background-position: center center;
  background-size: cover;
  margin: 30px 0;
  max-height: 600px;
  min-height: 200px;
  width: 100%;
}

.entry-meta {
  @include responsive-font(14px, 15px);
}
