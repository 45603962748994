.about-template {
  @import '../component/three-image-grid';

  @import '../component/large-image-stats';
}

.about-content {
  max-width: 1000px;

  h1,
  h2,
  h3 {
    @include responsive-font(28px, 30px);
    color: $primary;
    font-weight: $bold !important;
    line-height: 40px;
    margin-top: 0;
  }

  p {
    @include responsive-font(16px, 18px);
    margin-bottom: 24px;
  }
}
