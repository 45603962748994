$primary: #327e3d;
$secondary: #303434;

$text-dark: #262626;
$text-light: #fff;
$text-nav: #4a4a4a;

$border-dark: #222;
$border-light: #f1f1f1;

$dark-background: #7c878e;

$link-color: babyblue;

$main-footer-color: $primary;

$box-shadow-color: rgba(0, 0, 0, .16);

$button: $primary;

@mixin darken($color) {
  background: darken($color, 4);
}

@mixin lighten($color) {
  background: lighten($color, 4);
}

$fast: 150ms;
$fastish: 250ms;
$slow: .5s;
