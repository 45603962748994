.sidebar-wrap {

  @media (max-width: 767px) {
    border-top: 1px solid $primary;
    margin-top: 40px;
  }

  section {
    &:not(:first-of-type) {
      margin-top: 60px;
    }

    @media (max-width: 766px) {
      margin-top: 30px;
    }

    img {
      height: auto;
      margin-bottom: 10px;
      margin-top: 10px;
      max-width: 300px;
      width: 100%;
    }
  }

  .widget-title {
    @include responsive-font(18px, 22px);
    color: $primary;
    font-weight: bold !important;
  }

  p {
    @include responsive-font(16px, 15px);
  }

  button {
    display: block;
  }
}
