/* ----Box Shadows----------------------------------------------------------- */
// @include shadow(1) ~ shadow(6);

@mixin shadow($depth: 2) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}

@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

  @return 0 $primary-offset $blur $color;
}

@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

  @return 0 $primary-offset $blur $color;
}

/* ----Border Radius--------------------------------------------------------- */
// @include rounded;

@mixin rounded($radius: 8px) {
  background-clip: padding-box;
  border-radius: $radius;
}

/* ----Borders--------------------------------------------------------------- */
// @include border;

@mixin border($width: 1px, $color: $primary) {
  border: $width solid $color;
}

/* ----Breakpoints----------------------------------------------------------- */
// @include mq('tablet') {
//  margin: 10px
// }

$breakpoints: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);

@mixin mq($width, $type: min) {
  @if map-has-key($breakpoints, $width) {
    $width: map-get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

/* ----Truncate Strings------------------------------------------------------ */
// @include truncate(40px)

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* ----z-index tracking------------------------------------------------------ */
@function z($name) {
  @if index($z-indexes, $name) {
    @return (length($z-indexes) - index($z-indexes, $name)) + 1;
  } @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
    @return null;
  }
}

// 1. add class name in index below
$z-indexes: (
  'class-name',
  'another-class-name'
);

// 2. add z-index('class-name') to selector
