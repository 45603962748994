.three-image-grid {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;


  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__left-side {
    position: relative;
    width: 69%;

    @media (max-width: 768px) {
      margin-bottom: 20px;
      width: 100%;
    }

    &--image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 400px;

      @media (max-width: 600px) {
        height: 250px;
      }
    }
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    width: 29%;

    @media (max-width: 768px) {
      flex-direction: row;
      width: 100%;
    }

    a {
      flex: 1;
      text-decoration: none;
      height: 200px;

      @media (max-width: 768px) {
        height: 200px;
        width: 49%;
      }

      @media (max-width: 600px) {
        height: 150px;
      }
    }

    p {
      @include responsive-font(18px, 20px);
      color: $text-light;
      margin: 0;
      max-width: 100px;
    }

    &--top,
    &--bottom {
      align-items: flex-end;
      display: flex;
      padding: 20px;
      transition: all $fast;

      &:hover {
        opacity: .8;
      }
    }

    &--top {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: calc(100% - 10px);
      margin-bottom: 10px;
      width: 100%;

      @media (max-width: 768px) {
        margin: 0 10px 0 0;
      }
    }

    &--bottom {
      @include block-cut(56px, $text-light);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: calc(100% - 10px);
      margin-top: 10px;
      overflow: hidden;

      &::before {
        border-left-color: $text-light;
        right: -10px;
        transform: rotate(45deg) translate(40px, 10px);
      }

      @media (max-width: 768px) {
        margin: 0 0 0 10px;
      }

    }
  }
}
