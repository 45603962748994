/*

Theme Name: Hive Starter
Author: Chatter Buzz Media
Author URI: http://chatterbuzzmedia.com
Version: 1.0.0

Compiling all import files...

*/
/* ----Responsive Font Sizing------------------------------------------------ */
h1 {
  font-size: 30px;
}

@media only screen and (min-width: 480px) {
  h1 {
    font-size: calc(30px + 4 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 34px;
  }
}

h2 {
  font-size: 26px;
}

@media only screen and (min-width: 480px) {
  h2 {
    font-size: calc(26px + 4 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  h2 {
    font-size: 30px;
  }
}

h3 {
  font-size: 22px;
}

@media only screen and (min-width: 480px) {
  h3 {
    font-size: calc(22px + 4 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  h3 {
    font-size: 26px;
  }
}

h4,
h5,
h6 {
  font-size: 18px;
}

@media only screen and (min-width: 480px) {
  h4,
  h5,
  h6 {
    font-size: calc(18px + 4 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  h4,
  h5,
  h6 {
    font-size: 22px;
  }
}

/* ----Box Shadows----------------------------------------------------------- */
/* ----Border Radius--------------------------------------------------------- */
/* ----Borders--------------------------------------------------------------- */
/* ----Breakpoints----------------------------------------------------------- */
/* ----Truncate Strings------------------------------------------------------ */
/* ----z-index tracking------------------------------------------------------ */
body {
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  line-height: 28px;
}

@media only screen and (min-width: 480px) {
  body {
    font-size: calc(16px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  body {
    font-size: 18px;
  }
}

.container {
  position: relative;
}

.site-button {
  font-size: 16px;
  background: #327e3d;
  border: 0;
  color: #fff;
  display: inline-block;
  padding: 4px 24px;
  text-decoration: none;
  transition: all 150ms;
}

@media only screen and (min-width: 480px) {
  .site-button {
    font-size: calc(16px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .site-button {
    font-size: 18px;
  }
}

.site-button:hover {
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
  color: #fff;
  opacity: .95;
  text-decoration: none;
  transform: scale(1.04);
}

.search-form {
  position: relative;
  width: 203px;
}

.search-form input {
  border: 0;
  margin-bottom: 20px;
  padding-right: 40px !important;
  width: 203px;
}

.search-form i {
  color: #327e3d;
  position: absolute;
  right: 14px;
  top: 14px;
}

input[type="text"],
input[type="email"],
textarea {
  -webkit-appearance: none;
  border: 1px solid #222;
  border-radius: 0;
  font-weight: 400;
  padding: 8px 12px;
  transition: all 150ms;
  width: 100%;
}

input[type="text"]:focus, input[type="text"]:active,
input[type="email"]:focus,
input[type="email"]:active,
textarea:focus,
textarea:active {
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
  border: 1px solid #327e3d;
  outline: none;
  transform: scale(1.04);
}

.wpcf7 label {
  font-weight: 700;
}

.element-heading {
  color: #327e3d;
  font-weight: 700;
  line-height: 40px;
  margin-top: 0;
}

@media (max-width: 762px) {
  .element-heading {
    line-height: 30px;
  }
}

.sub-text {
  font-size: 16px;
  font-weight: 300;
}

@media only screen and (min-width: 480px) {
  .sub-text {
    font-size: calc(16px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .sub-text {
    font-size: 18px;
  }
}

.site-footer {
  background: #327e3d;
  margin-top: 40px;
  padding: 20px 0;
}

.site-footer a {
  transition: all 150ms;
}

.site-footer a:hover {
  opacity: .8;
}

.site-footer__main {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 800px) {
  .site-footer__main {
    justify-content: center;
  }
}

.site-footer__main > div {
  min-width: 200px;
}

@media (max-width: 644px) {
  .site-footer__main > div {
    min-width: 350px;
  }
}

.site-footer__main h3 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
}

@media only screen and (min-width: 480px) {
  .site-footer__main h3 {
    font-size: calc(18px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .site-footer__main h3 {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .site-footer__main h3 {
    margin-bottom: 10px;
  }
}

.site-footer__main ul {
  padding: 0;
}

.site-footer__main ul li {
  list-style: none;
}

.site-footer__main ul li a {
  font-size: 14px;
  color: #fff;
  font-weight: 300 !important;
}

@media only screen and (min-width: 480px) {
  .site-footer__main ul li a {
    font-size: calc(14px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .site-footer__main ul li a {
    font-size: 16px;
  }
}

.site-footer__social {
  margin-bottom: 20px;
}

.site-footer__social a {
  align-items: center;
  background: #fff;
  border-radius: 50%;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  margin-right: 6px;
  width: 30px;
}

.site-footer__social a:hover {
  text-decoration: none;
}

.site-footer__social a i {
  color: #327e3d;
}

.site-footer__phone-block a {
  color: #fff;
  text-decoration: none;
}

.site-footer__copyright {
  color: #fff;
  font-size: 15px;
  font-weight: lighter;
  margin-top: 40px;
  text-align: center;
}

.notice-bar {
  background-color: #327e3d;
  color: #fff;
  padding: 10px;
  border-bottom: 14px solid #303434;
}

.notice-bar h1, .notice-bar h2, .notice-bar h3, .notice-bar h4, .notice-bar h5 {
  margin: 0 0 10px;
}

.notice-bar p {
  margin: 0;
  line-height: 1.3;
}

.notice-bar__close {
  background-color: #000;
  color: #fff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  border: 0;
  line-height: 1;
}

.notice-bar__close:hover {
  background: #fff;
  color: #000;
}

@media (max-width: 767px) {
  .sidebar-wrap {
    border-top: 1px solid #327e3d;
    margin-top: 40px;
  }
}

.sidebar-wrap section:not(:first-of-type) {
  margin-top: 60px;
}

@media (max-width: 766px) {
  .sidebar-wrap section {
    margin-top: 30px;
  }
}

.sidebar-wrap section img {
  height: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 300px;
  width: 100%;
}

.sidebar-wrap .widget-title {
  font-size: 18px;
  color: #327e3d;
  font-weight: bold !important;
}

@media only screen and (min-width: 480px) {
  .sidebar-wrap .widget-title {
    font-size: calc(18px + 4 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .sidebar-wrap .widget-title {
    font-size: 22px;
  }
}

.sidebar-wrap p {
  font-size: 16px;
}

@media only screen and (min-width: 480px) {
  .sidebar-wrap p {
    font-size: calc(16px + -1 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .sidebar-wrap p {
    font-size: 15px;
  }
}

.sidebar-wrap button {
  display: block;
}

.-search {
  border-top: 1px solid #f1f1f1;
}

.-search .element-heading a {
  font-size: 28px;
  color: #327e3d;
  display: block;
  padding: 20px;
  background: #f1f1f1;
}

@media only screen and (min-width: 480px) {
  .-search .element-heading a {
    font-size: calc(28px + 4 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .-search .element-heading a {
    font-size: 32px;
  }
}

.not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}

.not-found .page-404-title {
  color: #327e3d;
  font-size: 120px;
  margin: 0;
}

.not-found .page-404-explain {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.not-found form label span {
  display: none;
}

.not-found form input[type="search"] {
  background: #eee;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  font-weight: lighter;
  padding: 8px 12px;
}

.not-found form input[type="submit"] {
  background: #eee;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  font-weight: lighter;
  padding: 8px 12px;
}

.about-template .three-image-grid {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
}

@media (max-width: 768px) {
  .about-template .three-image-grid {
    flex-direction: column;
  }
}

.about-template .three-image-grid__left-side {
  position: relative;
  width: 69%;
}

@media (max-width: 768px) {
  .about-template .three-image-grid__left-side {
    margin-bottom: 20px;
    width: 100%;
  }
}

.about-template .three-image-grid__left-side--image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}

@media (max-width: 600px) {
  .about-template .three-image-grid__left-side--image {
    height: 250px;
  }
}

.about-template .three-image-grid__right-side {
  display: flex;
  flex-direction: column;
  width: 29%;
}

@media (max-width: 768px) {
  .about-template .three-image-grid__right-side {
    flex-direction: row;
    width: 100%;
  }
}

.about-template .three-image-grid__right-side a {
  flex: 1;
  text-decoration: none;
  height: 200px;
}

@media (max-width: 768px) {
  .about-template .three-image-grid__right-side a {
    height: 200px;
    width: 49%;
  }
}

@media (max-width: 600px) {
  .about-template .three-image-grid__right-side a {
    height: 150px;
  }
}

.about-template .three-image-grid__right-side p {
  font-size: 18px;
  color: #fff;
  margin: 0;
  max-width: 100px;
}

@media only screen and (min-width: 480px) {
  .about-template .three-image-grid__right-side p {
    font-size: calc(18px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .about-template .three-image-grid__right-side p {
    font-size: 20px;
  }
}

.about-template .three-image-grid__right-side--top, .about-template .three-image-grid__right-side--bottom {
  align-items: flex-end;
  display: flex;
  padding: 20px;
  transition: all 150ms;
}

.about-template .three-image-grid__right-side--top:hover, .about-template .three-image-grid__right-side--bottom:hover {
  opacity: .8;
}

.about-template .three-image-grid__right-side--top {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100% - 10px);
  margin-bottom: 10px;
  width: 100%;
}

@media (max-width: 768px) {
  .about-template .three-image-grid__right-side--top {
    margin: 0 10px 0 0;
  }
}

.about-template .three-image-grid__right-side--bottom {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100% - 10px);
  margin-top: 10px;
  overflow: hidden;
}

.about-template .three-image-grid__right-side--bottom::before {
  border-bottom: 56px solid #fff;
  border-left: 56px solid #fff;
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  transition: all 150ms;
  width: 0;
  z-index: 40;
}

.about-template .three-image-grid__right-side--bottom::before {
  border-left-color: #fff;
  right: -10px;
  transform: rotate(45deg) translate(40px, 10px);
}

@media (max-width: 768px) {
  .about-template .three-image-grid__right-side--bottom {
    margin: 0 0 0 10px;
  }
}

.about-template .large-image-stats {
  margin-top: 60px;
}

.about-template .large-image-stats__top-image {
  margin-bottom: 14px;
}

.about-template .large-image-stats__top-image img {
  height: auto;
  width: 100%;
}

.about-template .large-image-stats__stats-wrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 850px) {
  .about-template .large-image-stats__stats-wrap {
    flex-wrap: wrap;
  }
}

.about-template .large-image-stats__stats-each {
  align-items: center;
  color: #fff;
  display: flex;
  height: 280px;
  margin-bottom: 10px;
  min-width: 200px;
  padding: 10px 40px;
  text-align: center;
  width: 32.6%;
}

@media (max-width: 850px) {
  .about-template .large-image-stats__stats-each {
    flex: 1;
    height: 250px;
    justify-content: center;
    padding: 10px 20px;
  }
}

.about-template .large-image-stats__stats-each:nth-of-type(1) {
  background: #279a16;
}

.about-template .large-image-stats__stats-each:nth-of-type(2) {
  background: #2b6081;
}

.about-template .large-image-stats__stats-each:nth-of-type(3) {
  position: relative;
  background: #2e2e2e;
}

.about-template .large-image-stats__stats-each:nth-of-type(3)::before {
  border-bottom: 40px solid #fff;
  border-left: 40px solid #2e2e2e;
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  transition: all 150ms;
  width: 0;
  z-index: 40;
}

.about-template .large-image-stats__stats-each h3 {
  font-size: 20px;
  margin: 0 0 20px;
}

@media only screen and (min-width: 480px) {
  .about-template .large-image-stats__stats-each h3 {
    font-size: calc(20px + 4 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .about-template .large-image-stats__stats-each h3 {
    font-size: 24px;
  }
}

.about-template .large-image-stats__stats-each p {
  font-size: 12px;
}

@media only screen and (min-width: 480px) {
  .about-template .large-image-stats__stats-each p {
    font-size: calc(12px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .about-template .large-image-stats__stats-each p {
    font-size: 14px;
  }
}

.about-content {
  max-width: 1000px;
}

.about-content h1,
.about-content h2,
.about-content h3 {
  font-size: 28px;
  color: #327e3d;
  font-weight: 700 !important;
  line-height: 40px;
  margin-top: 0;
}

@media only screen and (min-width: 480px) {
  .about-content h1,
  .about-content h2,
  .about-content h3 {
    font-size: calc(28px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .about-content h1,
  .about-content h2,
  .about-content h3 {
    font-size: 30px;
  }
}

.about-content p {
  font-size: 16px;
  margin-bottom: 24px;
}

@media only screen and (min-width: 480px) {
  .about-content p {
    font-size: calc(16px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .about-content p {
    font-size: 18px;
  }
}

.general-with-sidebar {
  border-right: 1px solid #327e3d;
  padding-right: 40px;
}

@media (max-width: 767px) {
  .general-with-sidebar {
    border-right: 0;
    padding-right: 0;
  }
}

.general-with-sidebar .section:not(:last-of-type) {
  margin-bottom: 60px;
}

.general-with-sidebar .section__title {
  margin-bottom: 20px;
}

.general-with-sidebar .section__image {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  margin-bottom: 20px;
  overflow: hidden;
}

.general-with-sidebar .section__image::before {
  border-bottom: 110px solid #fff;
  border-left: 110px solid #fff;
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  transition: all 150ms;
  width: 0;
  z-index: 40;
}

.general-with-sidebar .section__image::before {
  border-left-color: #fff;
  right: -10px;
  transform: rotate(45deg) translate(80px, 10px);
}

@media (max-width: 768px) {
  .general-with-sidebar .section__image {
    position: relative;
    height: 250px;
  }
  .general-with-sidebar .section__image::before {
    border-bottom: 100px solid #fff;
    border-left: 100px solid #fff;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    transition: all 150ms;
    width: 0;
    z-index: 40;
  }
}

.general-with-sidebar .section__content {
  font-size: 16px;
}

@media only screen and (min-width: 480px) {
  .general-with-sidebar .section__content {
    font-size: calc(16px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .general-with-sidebar .section__content {
    font-size: 18px;
  }
}

.general-with-sidebar .section button {
  margin-top: 10px;
}

.manufacturer {
  margin-top: 40px;
}

.manufacturer__wrap {
  margin-top: 40px;
}

.manufacturer__wrap:not(:last-of-type) {
  margin-bottom: 40px;
}

.manufacturer__logo {
  max-height: 100px;
  width: auto;
}

.manufacturer__content-wrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

@media (max-width: 650px) {
  .manufacturer__content-wrap {
    flex-direction: column;
  }
}

.manufacturer__product-image {
  position: relative;
  overflow: hidden;
  width: 35%;
}

.manufacturer__product-image::before {
  border-bottom: 120px solid #fff;
  border-left: 120px solid #fff;
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  transition: all 150ms;
  width: 0;
  z-index: 40;
}

@media (max-width: 650px) {
  .manufacturer__product-image {
    width: 100%;
  }
}

.manufacturer__product-image::before {
  border-left-color: #fff;
  right: -10px;
  transform: rotate(45deg) translate(100px, 10px);
}

.manufacturer__product-image img {
  height: auto;
  width: 100%;
}

.manufacturer__description {
  background: #f1f1f1;
  padding: 20px;
  width: 62%;
}

@media (max-width: 650px) {
  .manufacturer__description {
    width: 100%;
    margin-top: 20px;
  }
}

.manufacturer__button {
  display: flex;
  justify-content: flex-end;
}

.section-image-stat:not(:first-of-type) {
  margin-top: 40px;
}

.section-image-stat__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  margin-bottom: 20px;
}

.section-image-stat__content-wrap {
  display: flex;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .section-image-stat__content-wrap {
    flex-direction: column;
  }
}

.section-image-stat__content-wrap > div:first-child {
  margin-right: 40px;
  width: 70%;
}

@media (max-width: 768px) {
  .section-image-stat__content-wrap > div:first-child {
    width: 100%;
  }
}

.section-image-stat__content-wrap > div:last-child {
  width: 30%;
}

@media (max-width: 768px) {
  .section-image-stat__content-wrap > div:last-child {
    width: 100%;
  }
}

.section-image-stat .single-stat {
  position: relative;
  align-items: center;
  background: #2b6081;
  color: #fff;
  display: flex;
  height: 190px;
  justify-content: center;
  margin-bottom: 10px;
  min-width: 200px;
  padding: 10px 40px;
  text-align: center;
  width: 100%;
}

.section-image-stat .single-stat::before {
  border-bottom: 40px solid #fff;
  border-left: 40px solid #2b6081;
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  transition: all 150ms;
  width: 0;
  z-index: 40;
}

.product-page {
  border-right: 1px solid #327e3d;
  padding-right: 40px;
}

@media (max-width: 767px) {
  .product-page {
    border-right: 0;
    padding-right: 0;
  }
}

.product-info__image {
  max-height: 100px;
  width: auto;
}

.product-info .element-heading {
  margin-bottom: 40px;
  margin-top: 40px;
}

.product-info__gallery {
  margin: 10px 0;
}

.product-info__gallery img {
  height: auto;
  max-width: 32%;
}

.product-info__content {
  font-size: 16px;
}

@media only screen and (min-width: 480px) {
  .product-info__content {
    font-size: calc(16px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .product-info__content {
    font-size: 18px;
  }
}

.product-list {
  margin-bottom: 60px;
  margin-top: 60px;
}

.product-list .element-heading {
  margin-bottom: 40px;
}

.product-list__each {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 500px) {
  .product-list__each {
    flex-direction: column;
  }
}

.product-list__each:not(:last-of-type) {
  margin-bottom: 60px;
}

.product-list__image {
  align-self: center;
  width: 30%;
}

@media (max-width: 500px) {
  .product-list__image {
    width: 100%;
  }
}

.product-list__image img {
  height: auto;
  width: 100%;
}

.product-list__meta {
  width: 65%;
}

@media (max-width: 500px) {
  .product-list__meta {
    width: 100%;
  }
}

.product-list__meta h3 {
  font-size: 22px;
  font-weight: 700 !important;
}

@media only screen and (min-width: 480px) {
  .product-list__meta h3 {
    font-size: calc(22px + -2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .product-list__meta h3 {
    font-size: 20px;
  }
}

.product-list__meta p {
  font-size: 17px;
}

@media only screen and (min-width: 480px) {
  .product-list__meta p {
    font-size: calc(17px + -1 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .product-list__meta p {
    font-size: 16px;
  }
}

.-contact__top-text {
  font-size: 18px;
  margin-bottom: 40px;
  text-align: center;
}

@media only screen and (min-width: 480px) {
  .-contact__top-text {
    font-size: calc(18px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .-contact__top-text {
    font-size: 20px;
  }
}

.-contact__content-wrap {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 700px) {
  .-contact__content-wrap {
    flex-direction: column-reverse;
  }
}

.-contact__form {
  width: 48%;
}

@media (max-width: 700px) {
  .-contact__form {
    width: 100%;
  }
}

.-contact__form input[type="text"],
.-contact__form input[type="email"],
.-contact__form textarea {
  width: 100%;
}

.-contact__locations {
  width: 48%;
}

@media (max-width: 700px) {
  .-contact__locations {
    width: 100%;
    margin-bottom: 60px;
  }
}

.-contact__locations iframe {
  height: 397px;
  width: 100%;
}

.-contact__locations--meta .classification {
  font-size: 18px;
  font-weight: 700 !important;
}

@media only screen and (min-width: 480px) {
  .-contact__locations--meta .classification {
    font-size: calc(18px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .-contact__locations--meta .classification {
    font-size: 20px;
  }
}

.-contact__locations--meta .phone-number a {
  color: inherit;
  font-weight: 700;
}

.-contact__locations--meta .location-name {
  color: #327e3d;
  font-weight: 700;
  margin-bottom: 0;
}

.-contact__locations--meta address {
  max-width: 330px;
}

.-contact__locations--map-wrap {
  position: relative;
  overflow: hidden;
}

.-contact__locations--map-wrap::before {
  border-bottom: 60px solid #fff;
  border-left: 60px solid #fff;
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  transition: all 150ms;
  width: 0;
  z-index: 40;
}

.-contact__locations--map-wrap::before {
  border-left-color: #fff;
  right: -10px;
  transform: rotate(45deg) translate(35px, 0px);
}

/*

  HIVE STARTER KIT Rundown
  Included is one template for the 404 page. It was created using SCSSs %placeholder
  element. This means the CSS is not compiled unless the %placeholder
  element is called.

  The template was created in ../templates/template-404
  It is imported into this file, and can be used by extending the placeholder
  element onto .not-found

  Currently there is one template:
  %404-1

*/
.not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}

.not-found .page-404-title {
  color: #327e3d;
  font-size: 120px;
  margin: 0;
}

.not-found .page-404-explain {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.not-found form label span {
  display: none;
}

.not-found form input[type="search"] {
  background: #eee;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  font-weight: lighter;
  padding: 8px 12px;
}

.not-found form input[type="submit"] {
  background: #eee;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  font-weight: lighter;
  padding: 8px 12px;
}

.post-featured-image {
  background-position: center center;
  background-size: cover;
  margin: 30px 0;
  max-height: 600px;
  min-height: 200px;
  width: 100%;
}

.entry-meta {
  font-size: 14px;
}

@media only screen and (min-width: 480px) {
  .entry-meta {
    font-size: calc(14px + 1 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .entry-meta {
    font-size: 15px;
  }
}

.home__section-1,
.home__section-2,
.home__section-3 {
  margin-bottom: 60px;
}

.home-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home-blocks a {
  position: relative;
  text-decoration: none;
}

.home-blocks__each {
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  width: 49%;
}

@media (max-width: 762px) {
  .home-blocks__each {
    width: 100%;
  }
}

.home-blocks__each:last-of-type .home-blocks__each-meta {
  position: relative;
}

.home-blocks__each:last-of-type .home-blocks__each-meta::before {
  border-bottom: 24px solid #fff;
  border-left: 24px solid #303434;
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  transition: all 150ms;
  width: 0;
  z-index: 40;
}

.home-blocks__each:hover .home-blocks__each-meta {
  background: #327e3d;
}

.home-blocks__each:hover .home-blocks__each-meta::before {
  border-left-color: #327e3d;
}

.home-blocks__each:hover .home-blocks__each-image {
  opacity: .8;
}

.home-blocks__each-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  height: 250px;
}

.home-blocks__each-meta {
  background: #303434;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 20px;
  transition: all 150ms;
}

.home-blocks__each-meta h3 {
  font-size: 17px;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (min-width: 480px) {
  .home-blocks__each-meta h3 {
    font-size: calc(17px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .home-blocks__each-meta h3 {
    font-size: 19px;
  }
}

.home-blocks__each-meta i {
  color: #fff;
  margin-left: 8px;
  position: relative;
  top: 2px;
}

.home-blocks__each-meta p {
  font-size: 14px;
  color: #fff;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 10px;
}

@media only screen and (min-width: 480px) {
  .home-blocks__each-meta p {
    font-size: calc(14px + 1 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .home-blocks__each-meta p {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .home-blocks__each-meta p {
    line-height: 24px;
  }
}

.home__about-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  margin-bottom: -40px;
  padding: 40px 0;
}

.home__about-inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .home__about-inner {
    flex-direction: column;
  }
}

.home__about-content {
  color: #fff;
  width: 55%;
}

@media (max-width: 768px) {
  .home__about-content {
    width: 100%;
  }
}

.home__about-content h2 {
  font-size: 34px;
  text-transform: uppercase;
}

@media only screen and (min-width: 480px) {
  .home__about-content h2 {
    font-size: calc(34px + 8 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .home__about-content h2 {
    font-size: 42px;
  }
}

.home__about-content p {
  font-size: 15px;
}

@media only screen and (min-width: 480px) {
  .home__about-content p {
    font-size: calc(15px + 1 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .home__about-content p {
    font-size: 16px;
  }
}

.home__about-image {
  text-align: right;
  transition: all 150ms;
  width: 44%;
}

@media (max-width: 768px) {
  .home__about-image {
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }
}

.home__about-image img {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.22), 0 14px 56px rgba(0, 0, 0, 0.25);
  max-width: 100%;
}

/**************************
  VARS
**************************/
/**************************
  WP BASE BLOG STYLES
**************************/
.alignleft, img.alignleft {
  margin-right: 1.5em;
  display: inline;
  float: left;
}

.alignright, img.alignright {
  margin-left: 1.5em;
  display: inline;
  float: right;
}

.aligncenter, img.aligncenter {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both;
}

.wp-caption {
  margin-bottom: 1.5em;
  text-align: center;
  padding-top: 5px;
}

.wp-caption img {
  border: 0 none;
  padding: 0;
  margin: 0;
}

.wp-caption p.wp-caption-text {
  line-height: 1.5;
  font-size: 10px;
  margin: 0;
}

.wp-smiley {
  margin: 0 !important;
  max-height: 1em;
}

blockquote.left {
  margin-right: 20px;
  text-align: right;
  margin-left: 0;
  width: 33%;
  float: left;
}

blockquote.right {
  margin-left: 20px;
  text-align: left;
  margin-right: 0;
  width: 33%;
  float: right;
}

.hive-page-header a {
  color: #fff;
}

/**************************
  BLOG INDEX STYLES
**************************/
article.type-post:not(:last-of-type) {
  margin-bottom: 40px;
}

.blog-index__image {
  content: '';
  height: 350px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 150ms;
  position: relative;
}

.blog-index__image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all ease 450ms;
}

.blog-index__image:hover:before {
  background: rgba(50, 126, 61, 0.5);
}

@media (max-width: 768px) {
  .blog-index__image {
    height: 300px;
  }
}

@media (max-width: 600px) {
  .blog-index__image {
    height: 250px;
  }
}

.blog-index__meta a {
  text-decoration: none;
}

.blog-index__title {
  font-size: 24px;
  line-height: 30px;
  color: #000;
  transition: all ease 350ms;
}

@media only screen and (min-width: 480px) {
  .blog-index__title {
    font-size: calc(24px + 4 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .blog-index__title {
    font-size: 28px;
  }
}

.blog-index__title:hover {
  color: #327e3d;
}

.blog-index__excerpt {
  font-size: 14px;
  line-height: 1.5;
}

@media only screen and (min-width: 480px) {
  .blog-index__excerpt {
    font-size: calc(14px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .blog-index__excerpt {
    font-size: 16px;
  }
}

.blog-index__extra {
  font-size: 13px;
  padding: 5px 0;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 20px;
}

@media only screen and (min-width: 480px) {
  .blog-index__extra {
    font-size: calc(13px + 2 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .blog-index__extra {
    font-size: 15px;
  }
}

.blog-index__extra a {
  color: #000;
  transition: all ease 350ms;
}

.blog-index__extra a:hover {
  color: #303434;
}

.blog-index__read-more {
  float: right;
}

.blog-index__read-more a {
  background: #327e3d;
  display: inline-block;
  padding: 4px 20px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #327e3d;
  position: relative;
  top: -6px;
}

.blog-index__read-more a:hover {
  color: #327e3d;
  background: #fff;
}

/**************************
  BLOG SINGLE STYLES
**************************/
.blog-single__content {
  padding: 10px 0;
}

.blog-single__content h1 {
  color: #000;
  font-size: 28px;
}

.blog-single__content p,
.blog-single__content ul,
.blog-single__content ol {
  font-size: 18px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 480px) {
  .blog-single__content p,
  .blog-single__content ul,
  .blog-single__content ol {
    font-size: calc(18px + -1 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .blog-single__content p,
  .blog-single__content ul,
  .blog-single__content ol {
    font-size: 17px;
  }
}

.blog-single__content ol li {
  margin: 20px 0;
}

.blog-single__title {
  font-size: 26px;
  color: #327e3d;
  margin-bottom: 10px;
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #327e3d;
}

@media only screen and (min-width: 480px) {
  .blog-single__title {
    font-size: calc(26px + 8 * ((100vw - 480px) / 720));
  }
}

@media only screen and (min-width: 1200px) {
  .blog-single__title {
    font-size: 34px;
  }
}

.blog-single__image {
  content: '';
  height: 350px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .blog-single__image {
    height: 300px;
  }
}

@media (max-width: 600px) {
  .blog-single__image {
    height: 250px;
  }
}

/**************************
  BLOG SIDEBAR STYLES
**************************/
.blog-sidebar {
  padding-left: 40px;
}

.blog-sidebar aside section {
  background: #fff;
  padding-top: 0px;
  padding-bottom: 30px;
  margin-top: 0 !important;
}

.blog-sidebar aside section h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  padding: 15px 0;
}

.blog-sidebar aside section ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog-sidebar aside section ul li {
  border-bottom: 1px solid #e7e7e7;
  margin: 10px 0;
}

.blog-sidebar aside section ul li a {
  color: #000;
  font-size: 15px;
  line-height: 25px;
  text-decoration: none;
  transition: all 250ms;
}

.blog-sidebar aside section ul li a:hover {
  color: #303434;
}

/**************************
  BLOG PAGINATION STYLES
**************************/
.blog-pagination a {
  color: #262626;
}
