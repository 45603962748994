.site-button {
  @include responsive-font(16px, 18px);
  background: $primary;
  border: 0;
  color: $text-light;
  display: inline-block;
  padding: 4px 24px;
  text-decoration: none;
  transition: all $fast;

  &:hover {
    @include shadow(1);
    color: $text-light;
    opacity: .95;
    text-decoration: none;
    transform: scale(1.04);
  }
}
